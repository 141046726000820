.popup{
	position: fixed; 
  margin: auto;
	width: 100%;
	height: 100%; 
	top: 0; 
	right: 0; 
	bottom: 0; 
	left: 0; 
    background: rgba(0,0,0,0.4); 
    z-index: 99999;  
    overflow-y: auto;
    display: none;
    .popup-inner{
    	width: 100%;
    	background: #fff;
    	margin: 100px auto 50px;
      padding: 50px;
      position: relative;
      border-radius: 10px;
      top: 0;
      z-index: 9999;
      max-width: 890px;
      height: auto;
      // @include transform(translateY(-50%));
      &.medium{
        max-width: 738px;
        padding: 47px;
      }
      .content-pop{
        margin: 0 auto;
        width: 100%;
        h5{
          margin: 0;
        }
        h6{
          margin-bottom: 20px;
        }
        figure{
          img{
            width: 100%;
            display: block;
            margin-bottom: 22px;
          }
        }
        figcaption{
          h5{
            color: #0F3B7D;
            font-weight: 700;
            margin-bottom: 13px;
          }
        }
      }
    }
    .close-popup{
    	position: absolute;
      right: 0;
      top: -50px;
    	img{
    		transition: .2s all ease-out;
    	}
    	&:hover{
    		img{
    			transform:rotate(90deg);
    		}
    	}
    }
    .overlay {position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto; z-index: 3;}
    .wrap-flex{
      display: flex;
      overflow-y: auto;
      figure{
        flex: 0 0 30%;
        max-width: 30%;
      }
      .text{
        flex: 0 0 70%;
        max-width: 70%;
        padding: 0 20px 0 40px;
        h5{
          margin-bottom: 5px;
          color: #0B2C5E;
          font-weight: 700;
        }
        span{
          font-size: 1.063rem;
          line-height: 1.5;
          color: #0087B5;
          display: block;
          margin-bottom: 10px;
        }
        article{
          max-height: 300px;
          overflow-y: auto;
          padding-right: 20px;
        }
      }
    }
    
    /* width */
    ::-webkit-scrollbar {
      width: 7px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #d7d7d7;
      border-radius: 5px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #898989;
      border-radius: 5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
}

#pop-homepage {
  .popup-inner  {
    max-width: 375px;
    padding: 0;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
  }
  .close-popup {
    top: -50px;
    display: block;
    img {
      display: block;
    }
  }
  .content-img {
    position: relative;
    img {
      display: block;
      height: auto;
      width: 100%;
    }
    a {
      display: block;

    }
    .img-link {
      width: 100px;
      position: absolute;
      left: 0;
      right: 0;
      top: auto;
      bottom: 30px;
      margin: auto;
    }
  }
}
/* responsive desktop
----------------------------------------------------------------------------------------------*/
// @media all and (max-width: 1600px) {}



/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
// @media all and (max-width: 1200px) {}



/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
  .popup{
    .popup-inner{
      max-width: unset;
      width: auto;
      margin: 80px 40px 40px;
    }
  }
}



/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
  .popup{
    &#pop-detail .popup-inner {
      margin: 0;
      padding-right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
    .popup-inner{
      border-radius: 0;
      padding: 35px 30px;
      margin: 0;
      max-height: 80%;
      &.medium {
        padding: 35px 30px;
      }
      .close-popup {
        right: 10px;
      }
      .content-pop {
        max-height: 450px;
        overflow: auto;
        padding-right: 20px;
        figure img {
          border-radius: 8px;
        }
      }
    }
    /* Track */
    ::-webkit-scrollbar-track {
      border-radius: 0;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      border-radius: 0;
    }
  }
  #pop-homepage {
    .popup-inner  {
      overflow: unset;
      min-height: 500px;
    }
  }
  #pop-dewan{
    .close-popup{
      top: 6px;
      right: 16px;
      z-index: 99999;
      position: fixed;
    }
    .popup-inner{
      margin: 10% 24px 0;
      max-height: 90%;
      overflow: auto;
      .wrap-flex{
        flex-flow: row wrap;
        max-height: 80%;
        overflow-y: auto;
        figure,.text{ flex: unset; width: 100%; max-width: 100%;}
        .text{
          padding: 0;
          margin-top: 10px;
          article{
            padding-right: 0;
            max-height: unset;
            overflow: visible;
          }
        }
      }
    }
  }
}
@media all and (max-width: 376px) {
  #pop-homepage {
    
    .popup-inner  {
      max-width: 375px;
      padding: 0;
      margin: 30px 20px;
      min-height: 0;
    }
  }
  
}
