/*=============================================================================================
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Your Name
==============================================================================================*/
/* helper
----------------------------------------------------------------------------------------------*/
@import "../helper/sass-lib";
// @import "../helper/slick";       // import your slick slider style
// @import "../milligram/Color";    // import your general color from milligram

/* general
----------------------------------------------------------------------------------------------*/
@import "../component/layout";
@import "../component/box-news";
@import "../component/popup";
@import "general";

/* home styling
----------------------------------------------------------------------------------------------*/
.bul-sec{
  position: absolute;
  left: 45px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  height: 300px;
  &:before{
    content: "";
    position: absolute;
    bottom: 10px;
    left: 21px;
    width: 2px;
    height: 100%;
    background: #E7EDF5;
  }
  ul{
    margin: 0;
    li{
      position: relative;
      transform: rotate(90deg);
      margin: 0;
      margin-bottom: 65px;
      padding-bottom: 11px;
      text-align: left;
      a{
        color: #0B2C5E;
        font-size: 0;
        font-weight: 700;
        position: relative;
        display: contents;
        &:after{
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 12px;
          height: 12px;
          border-radius: 100%;
          background: #E7EDF5;
        }
      }
      &.active{
        a{
          font-size: 1rem;
          &:after{
            background: #FF5E25;
          }
        }
      }
    }
  }
}
.banner-home{
  position: relative;
  .slider{
    // height: 100vh;
    height: auto;
    overflow: hidden;
    position: relative;
    .list{
      position: relative;
      figure{
        position: relative;
        // height: 100vh;
        height: auto; 
        img{
          width: 100%;
          display: block;
          margin: 0;
          height: 100%;
          object-fit: cover;
        }
      }
      figcaption{
        position: absolute;
        top: 277px;
        width: 576px;
        left: 200px;
        color: #fff;
        h1{
          font-weight: 700;
          margin-bottom: 20px;
        }
        p{
          margin-bottom: 20px;
        }
      }
    }
    .slick-arrow{
      position: absolute;
      margin: 0;
      background: none;
      font-size: 0;
      border: 0;
      padding: 0;
      bottom: 80px;
      right: 135px;
      width: 40px;
      height: 40px;
      background: url(/images/material/arrow-right.svg) no-repeat center;
      &.slick-prev{
        transform: rotate(180deg);
        right: 170px;
        z-index: 3;
      }
    }
  }
  .bul-sec{
    &:before{
      background: #E7EDF5;
      opacity: 0.1;
      left: 25px;
    }
    ul{
      li{
        a{
          color: #fff;
          opacity: 0.1;
          &:after{
            background: #47637C;
          }
        }
        &.active{
          a{
            &:after{
              background: #FF5E25;
            }
          }
        }
      }
    }
  }
  .numbers{
    position: absolute;
    bottom: 120px;
    right: 135px;
    z-index: 9;
    color: #fff;
    .active{
      font-size: 2.125rem;
      font-weight: 700;
      padding-right: 10px;
    }
    .total{
      font-size: 0.875rem;
      font-weight: 700;
      letter-spacing: 0.75px;
    }
  }
}
.about-home{
  padding: 70px 0 0px 0;
  position: relative;
  .wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .with-bg{
    width: 558px;
  }
  figcaption{
    width: calc( 100% - 685px );
  }
}
.product-highlight-home{
  background: url( /images/material/bg-highlight.png ) no-repeat;
  object-fit: cover;
  background-size: 100%;
  padding: 145px 0;
  position: relative;
  .title{
    margin-bottom: 60px;
  }
  .slider-highlight{
    margin-right: calc(((100vw - 1190px) / 2) * -1);
    .list{
      position: relative;
      display: block;
      padding-right: 47px;
      width: 440px;
      transition: .2s all ease-out;
      &:after{
        border-radius: 16px;
        background: linear-gradient(89.05deg, rgba(0, 17, 36, 0) 7.35%, rgba(0, 21, 44, 0.9) 49.51%);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        right: 47px;
        top: 0%;
        // width: 100%;
      }
      figure{
        position: relative;
        border-radius: 16px;
        &::after{
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(185.57deg, rgba(1, 27, 55, 0) 43.51%, #022A55 91.32%);
          border-radius: 16px;
        }
        img{
          border-radius: 16px;
          display: block;
          height: auto;
          width: 100%;
        }
      }
      figcaption{
        position: absolute;
        bottom: 60px;
        padding: 0 50px;
        width: calc(100% - 47px);
        h4{
          color: #E7EDF5;
          font-weight: 700;
          text-transform: uppercase;
          margin: 0;
          transition: .2s all ease-out;
        }
        h6{
          color: #fff;
          margin: 0;
          margin-top: 13px;
          display: none;
        }
      }
      &:hover{
        transition: .2s all ease-out;
        figcaption{
          h6{
            display: block;
            transition: .2s all ease-out;
          }
        }
      }
    }
    .slick-active{
      .list{
        &::after{
          display: none;
        }
      }
    }
    .slick-arrow {
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 9;
      &.arrow-prev {
        left: 0;
      }
      &.arrow-next {
        right: 10px;
      }
      &.slick-disabled {
        display: none !important;
      }
      svg g {
        opacity: 1;
        path {
          stroke: #f5f5f5;
        }
      }
    }
  }
  .bul-sec{
    &:before{
      left: 29px;
      bottom: 13px;
    }
  }
}
.news-home{
  background:url(/images/material/bg-news.png) no-repeat bottom;
  background-size: 100%;
  object-fit: cover;
  padding-bottom: 133px;
  position: relative;
  .bul-sec{
    &:before{
      left: 42px;
      bottom: -70px;
    }
    ul{
      li{
        a{
          &:after{
            left: unset;
            right: 0;
          }
        }
      }
    }
  }
  
}
.contact-pop{
  display: block;
  position: fixed;
  bottom: 90px;
  right: 25px;
  width: 82px;
  height: 82px;
  background: #fff;
  box-shadow: 0px 4px 40px 10px rgba(0, 38, 68, 0.15);
  border-radius: 100%;
  text-align: center;
  padding: 13px 0;
  z-index: 9;
  cursor: pointer;
  img{
    display: block;
    height: auto;
    margin: 0 auto;
    width: auto;
  }
  h6{
    font-size: 0.813rem;
    margin: 0;
  }
}
/* responsive desktop
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1440px) {
  .bul-sec {
    left: 25px;
  }
}



/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1280px) {
  .slider-news {
    .slick-arrow {
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 9;
      &.arrow-prev {
        left: -50px;
      }
      &.arrow-next {
        right: -50px;
      }
      &.slick-disabled {
        opacity: .5;
      }
    }
  }
}
@media all and (max-width: 1280px) {
  .bul-sec{
    left: 0px;
    height: 220px;
    &::before{
      bottom: -30px;
    }
    ul{
      li{
        margin-bottom: 40px;
        &.active{
          a{
            font-size: 0;
          }
        }
        a{
          &:after{
            bottom: 39px;
          }
        }
      }
    }
  }
  .about-home{
    padding-top: 60px;
    .bul-sec{
      li{
        a{
          &::after{
            bottom: 35px;
          }
        }
      }
    }
    .with-bg{
      width: 425px;
    }
    figcaption{
      width: calc(100% - 479px);
    }
  }
  .product-highlight-home{
    padding: 100px 0;
    .title{
      margin-bottom: 50px;
    }
    .bul-sec{
      &::before{
        left: 25px;
        bottom: -30px;
      }
    }
    .slider-highlight{
      margin-right: -50px;
      .list{
        width: auto;
        padding-right: 30px;
        figure{
          width: auto;
        }
        figcaption{
          width: calc(100% - 30px);
          padding: 0 37px;
          bottom: 40px;
          right: 30px;
          h4{
            font-size: 1.25rem;
          }
          h6 {
            display: block;
          }
        }
      }
    }
  }
  .news-home{
    padding-bottom: 70px;
    .bul-sec{
      &::before{
        left: 25px;
        bottom: -17px;
      }
    }
    .title.twosides .slick-arrow {
      display: none;
    }
  }
  .banner-home{
    .slider{
      .list{
        figcaption{
          left: 50px;
          width: 400px;
          top:227px; 
        }
      }
      .slick-arrow{
        right: 50px;
        &.slick-prev{
          right: 90px;
        }
      }
    }
    .numbers{
      right: 50px;

    }
  }
  .contact-pop{
    width: 50px;
    height: 50px;
    padding: 9px 0;
    img{
      width: 20px;
    }
    h6{
      font-size: 0.5rem;
    }
  }
}



/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
  .bul-sec{
    top: unset;
    bottom: 80px;
  }
  .banner-home{
    .slider{
      .list{
        figcaption{
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
  .about-home, .product-highlight-home, .news-home{
    .bul-sec{display: none;}
  }
  .about-home{
    padding-top: 80px;
    .with-bg{
      width: 315px;
      height: 387px;
    }
    figcaption{
      width: calc(100% - 350px);
    }
  }
  .product-highlight-home{
    .slider-highlight{
      margin-right: -50px;
      .list{
        padding-right: 20px;
        figure, figcaption{
          width: 250px;
        }
        figcaption{
          padding: 0 25px;
          h6{
            font-size: 0.875rem;
            margin-top: 11px;
          }
        }
      }
    }
  }
}



/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
  .bul-sec{
    display: none;
  }
  .banner-home{
    .slider{
      .list{
        figcaption{
          left: 30px;
          width: 250px;
        }
      }
      .slick-arrow{
        bottom: 10px;
        width: 25px;
        height: 25px;
        right: 30px;
        &.slick-prev{
          right: 60px;
        }
      }
    }
    .numbers{
      right: 30px;
      bottom: 50px;
      .active{
        font-size: 1.25rem;
      }
      .total{
        font-size: 0.875rem;
      }
    }
  }
  .about-home{
    padding-top: 44px;
    .wrapper{
      display: block;
    }
    figcaption{
      width: 100%;
    }
    .with-bg{
      width: 100%;
    }
  }
  .product-highlight-home{
    padding: 50px 0;
    .slider-highlight{
      margin-right: -30px;
      .list{
        figure, figcaption{
          width: 220px;
        }
        figcaption{
          padding: 0 20px;
          bottom: 20px;
          h4{
            font-size: 1.063rem;
          }
          h6{
            margin-top: 5px;
          }
        }
      }
    }
  }
  .slider-news .slick-arrow {
    top: 65%;
    transform: scale(.6);
    transform-origin: center;
    &.arrow-prev {
      left: -45px;
    }
    &.arrow-next {
      right: -45px;
    }
  }
  .contact-pop{
    bottom: 30px;
  }
}