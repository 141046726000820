.slider-news,
.listing-news{
  &.slick-slider .slick-track,
  &.slick-slider .slick-list {
    display: flex;
  }
  .slick-slide {
    height: 100%;
    > div, > div .list {
       height: 100%;
     }
  }
  .list{
    display: block;
    padding: 12px;
    .box {
      box-shadow: 0 10px 40px -30px rgba(0, 36, 68, 0.1);
      position: relative;
      border-radius: 8px;
      overflow: hidden;
      background: #fff;
      height: 100%;
    }
    figure{
      border-radius: 8px 8px 0px 0px;
      position: relative;
      &::after{
        content: "";
        position: absolute;
        opacity: 0.8;
        background: linear-gradient(180deg, rgba(0, 43, 107, 0) 0%, #001C45 100%);
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 8px 8px 0px 0px;
      }
      img{
        display: block;
        height: auto;
        width: 100%;
      }
    }
    figcaption{
      background: #FFFFFF;
      opacity: 0.8;
      border-radius: 8px;
      padding: 35px;
      transition: .2s all ease-out;
      h6{
        color: #33BAE8;
        font-weight: 700;
        margin-bottom: 4px;
      }
      h5{
        color: #1A2537;
        margin-bottom: 4px;
      }
      p{
        color: #808690;
        margin: 0;
      }
    }
    &:hover{
      .box{
        box-shadow: 0px 4px 40px 0px rgba(0, 38, 68, 0.1);
        transition: .2s all ease-out;
      }
    }
  }
}
.listing-news {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 30px);
  margin-left: -15px;
  .list {
    width: 33.33%;
    padding: 0 15px;
    margin-bottom: 30px;
    .box {
      box-shadow: 0px 4px 40px 20px rgba(0, 38, 68, 0.1);
    }
    figcaption {
     
      padding: 22px 30px;
      h6 {
        font-size: 1.063rem;
      }
      h5 {
        font-size: 1.25rem;
        margin-bottom: 10px;
      }
    }
  }
}
/* responsive desktop
----------------------------------------------------------------------------------------------*/
// @media all and (max-width: 1600px) {}



/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1200px) {
  .slider-news,
  .listing-news{
    .list{
      figcaption{
        padding: 20px;
      }
    }
  }
}



/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
  .listing-news{
    .list{
      width: 50%;
      figcaption{
        h5{
          font-size: 1.063rem;
        }
      }
    }
  }
}



/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
  .slider-news{
    margin-top: 40px;
    .list{
      padding: 0;
      figcaption{
        h5{
          font-size: 1.063rem;
        }
      }
    }
  }
  .listing-news{
    .list{
      width: 100%;
      figcaption{
        h5{
          font-size: 1.063rem;
        }
      }
    }
  }
}