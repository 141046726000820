/* header
----------------------------------------------------------------------------------------------*/
header{
  position: fixed;
  width: 100%;
  height: 100px;
  top: 0;
  z-index: 9999;
  .wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    .logo {
      display: block;
      img {
        display: block;
        height: auto;
        width: auto;
      }
    }
    .right-header{
      align-items: center;
      display: flex;
      height: 100%;
      nav{
        height: 100%;
        margin-right: 24px;
        padding-right: 24px;
        position: relative;
        &:after {
          background: #fff;
          content: '';
          height: 25px;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          transition: .2s all ease-out;
          width: 1px;
        }
        ul{
          display: flex;
          height: 100%;
          margin: 0;
          padding: 0;
          position: relative;
          li{
            display: block;
            margin: 0;
            padding: 0;
            margin-left: 30px;
            &:last-child, &:nth-last-child(1),
            &:nth-last-child(2), &:nth-last-child(3) {
              &.dropdown .submenu {
                left: unset;
                right: 0;
              }
            }
            &.dropdown {
              &:hover .submenu {
                display: flex;
              }
              .parent {
                display: flex;
                .arrow {
                  cursor: pointer;
                  display: none;
                  margin-left: 10px;
                  width: 17px;
                  img {
                    display: block;
                  }
                }
              }
              .submenu {
                border-radius: 0 0 16px 16px;
                display: none;
                left: 0;
                position: absolute;
                top: 100%;
                // transform: translateX(-50%);
                transition: all ease-out 0.35s;
                width: max-content;
                &.sub-img {
                  left: 0;
                  // transform: translateX(0);
                }
                ul {
                  flex-direction: column;
                  padding: 25px;
                  width: 270px;
                  box-sizing: border-box;
                  &::before{
                    content: '';
                    background-color: #fff;
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    border-top: 6px solid #00A9E2;
                    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
                    border-radius: 0 0 16px 16px;
                  }
                  li {
                    margin: 0;
                    a {
                      display: block;
                      color: #4D5C6B;
                      font-size: 17px;
                      padding: 6px 0;
                      &.active, &:hover {
                        color: #00A9E2;
                      }
                    }
                    &.have-sub {
                      .parent .arrow {
                        display: block;
                        height: 100%;
                        position: relative;
                        top: 14px;
                      }
                      &.active {
                        > ul {
                          display: block;
                        }
                      }
                      ul {
                        border-bottom-right-radius: 16px;
                        bottom: auto;
                        display: none;
                        height: auto;
                        left: 95%;
                        position: absolute;
                        top: 0;
                        z-index: 99;
                        &::before{
                          box-shadow: unset;
                          border-radius: 0;
                        }
                        &.sub-second{
                          &::before{
                            border-radius: 0 0 16px 0;
                          }
                        }
                        &.sub-third{
                          &::before{
                            border-radius: 0 0 16px 0;
                          }
                        }
                      }
                    }
                  }
                }
                figure {
                  border-bottom-right-radius: 16px;
                  overflow: hidden;
                  position: relative;
                  width: 360px;
                  img {
                    display: block;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    width: 100%;
                  }
                  figcaption {
                    bottom: 0;
                    color: #CCEEF9;
                    font-weight: bold;
                    font-size: 34px;
                    left: 0;
                    line-height: 36px;
                    padding: 40px 35px;
                    position: absolute;
                    right: 0;
                    text-align: right;
                  }
                }
              }
            }
            a{
              color: #fff;
              display: block;
              position: relative;
              transition: .2s all ease-out;
              &.active, &:hover {
                color: #00A9E2;
              }
            }
          }
        }
        > ul > li {
          padding: 36px 0;
          position: relative;
        }
      }
      .tools{
        cursor: pointer;
        position: relative;
        padding: 20px 0;
        &:after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: auto;
          margin: auto;
          height: 3px;
          background: #F6CC51;
          transition: all ease-out 0.2s;
          width: 0;
        }
        &.act {
          &:after {
            transition: all ease-out 0.2s;
            width: 20px;
          }
        }
        .ico {
          display: block;
          img {display: block;}
        }
      }
      .language {
        margin-right: 25px;
        label {
          color: #fff;
          cursor: pointer;
          font-weight: normal;
          margin-bottom: 0;
          padding-right: 25px;
          position: relative;
          &:after {
            content: url('/images/material/ico-arr-top.svg');
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%) scale(.7) rotate(180deg);
          }
          .language__box {
            display: none;
            background: #fff;
            border-radius: 5px;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
            color: #222;
            left: 50%;
            padding: 10px;
            position: absolute;
            top: 100%;
            transform: translateX(-50%);
            width: max-content;
            a {
              display: inline-block;
              margin: 0 5px;
            }
          }
        }
        &__box {
          display: none;
        }
      }
      .search-header{
        .ico{
          background: url(/images/material/ico-search.svg) no-repeat center;
          width: 16px;
          height: 16px;
          transition: .2s all ease-out;
        }
        .drop-search{
          display: none;
          position: absolute;
          width: 207px;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
          border-radius: 6px;
          top: 100%;
          right: -40px;
          left: auto;
          background: #fff;
          .search{
            position: relative;
            input[type="text"]{
              background: #fff;
              height: 60px;
              padding: 10px 60px 10px 20px;
              box-sizing: border-box;
              margin: 0;
              font-size: 1rem;
              border: 0;
              &::placeholder {  
                color: #999999;
                font-style: italic;
                font-weight: 300;
                opacity: 1;  
              }
            }
            .btn{
              position:absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 20px;
              font-size: 0;
              padding: 0;
              min-width: unset;
              width: 40px;
              height: 40px;
              margin: 0;
              border-radius: 5px;
              border: 0;
              background: url('/images/material/ico-search-blue.svg') no-repeat center;
            }
          }
        }
      }
    }
  }
  .logo{
    .white, .color{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    .color{
      opacity: 0;
      transition: .2s all ease-out;
    }
  }
  .main-header {
    position: relative;
    z-index: 3;
    transition: .2s all ease-out;
    &::before{
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
      opacity: 0.3;
      transition: .2s all ease-out;
    }
  }
  .overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: rgba(57,57,57,.45);
    z-index: 2;
    display: none;
  }
  .right-side{
    display: flex;
    align-items: center;
    h6{
      display: none;
      margin: 0;
      margin-right: 15px;
      font-weight: 700;
      color: #FFFFFF;
    }
  }
  .burger-nav{
    display: none;
    flex-flow: column;
    justify-content: space-between;
    position: relative;
    margin-left: auto;
    width: 26px;
    // height: 20px;
    cursor: pointer;
    span{
      display: block;
      width: 100%;
      height: 2px;
      background: #F7F7F7;
      border-radius: 24px;
      margin-bottom: 5px;
      &:last-child{
        margin: 0;
        width: 20px;
        margin-left: 6px;
      }
    }
  }
  &.float{
    .logo{
      .color{
        transition: .2s all ease-out;
        opacity: 1;
      }
      .white{
        transition: .2s all ease-out;
        opacity: 0;
      }
    }
    .main-header{
      background: #fff;
      transition: .2s all ease-out;
      &::before{
        display: none;
        transition: .2s all ease-out;
      }
    }
    .wrapper{
      .right-header{
        nav{
          transition: .2s all ease-out;
          &::after {
            background: #35434F;
            transition: .2s all ease-out;
          }
          ul{
            li{
              a{
                color: #35434F;
                transition: .2s all ease-out;
                &.active, &:hover {
                  color: #00A9E2;
                }
              }
            }
          }
        }
        .search-header{
          .ico{
            background: url(/images/material/ico-search-gray.svg) no-repeat center;
            transition: .2s all ease-out;
          }
        }
        .language label {
          color: #35434F;
          &:after {
            content: url(/images/material/ico-arr-top-black.svg);
          }
        }
      }
    }
  }
}
footer{
  background: linear-gradient(180deg, #0F3B7D 0%, #0C3470 100%);
  padding: 77px 0;
  color: #B8C9E1;
  a{
    color: #B8C9E1;
  }
  .top{
    h6{
      font-weight: 700;
      margin-bottom: 20px;
      color: #FFFFFF;
    }
    .info-foot{
      display: flex;
      justify-content: space-between;
      ul {
        display: flex;
        justify-content: space-between;
        margin: 0;
        width: calc( 100% - 200px );
        li{
          width: 33%;
          &.loc{
            margin-right: 30px; 
            padding-left: 30px; 
            position: relative;
            &:before {
              position: absolute;
              width: 20px;
              height: 20px;
              content: "";
              left: 5px;
              top: 5px;
              background: url('/images/material/ico-loc.svg') no-repeat 0 0; 
            }
          }
          &.phone{
            margin-right: 30px; 
            padding-left: 30px; 
            position: relative;
            &:before {
              position: absolute;
              width: 20px;
              height: 20px;
              content: "";
              left: 5px;
              top: 5px;
              background: url('/images/material/ico-call.svg') no-repeat 0 0; 
            }
          }
          &.fax{
            margin-right: 30px; 
            padding-left: 30px; 
            position: relative;
            &:before {
              position: absolute;
              width: 20px;
              height: 20px;
              content: "";
              left: 5px;
              top: 5px;
              background: url('/images/material/ico-fax.svg') no-repeat 0 0; 
            }
          }
        }
      }
      figure{
        width: 140px;
        img {
          display: block;
          height: auto;
          width: auto;
        }
      }
    }
  }
  .bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    p{
      margin: 0;
    }
    .link-foot{
      display: flex;
      justify-content: space-between;
    }
    ul{
      display: flex;
      align-items: center;
      margin: 0;
      li{
        margin-left: 50px;
        &:first-child{
          margin-left: 0;
        }
      }
      &.socmed{
        margin-left: 165px;
        li{
          margin-left: 12px;
          &:first-child{
            margin-left: 0;
          }
        }
      }
    }
  }
}
.mobile-only{
  display: none;
}



/* footer
----------------------------------------------------------------------------------------------*/
// footer{}






/* responsive desktop
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1440px) {
  header .wrapper .right-header nav ul li{
    margin-left: 25px;
  }
  header .wrapper .right-header nav ul li.dropdown .submenu ul {
    width: 245px;
  }
}



/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1100px) {
  .mobile-only{
    display: block;
    &.tools-mobile {
      padding-right: 25px;
      position: relative;
      width: 74%;
      &:after {
        background: #fff;
        content: '';
        position: absolute;
        height: 30px;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
      }
    }
  }
  header{
    height: 80px;
    .right-side{
      display: flex;
      h6{
        display: block;
      }
    }
    .burger-nav{
      display: block;
    }
    .wrapper{
      height: 80px;
      .right-header{
        display: none;
        width: 400px;
        height: 100vh;
        right: -55px;
        top: 0;
        position: absolute;
        background: linear-gradient(126.04deg, #134A9C 17.11%, #087499 112.05%);
        padding: 0;
        z-index: 999999;
        .top-mobile{
          height: auto;
          display: flex;
          align-items: center;
          padding: 30px 23px 0;
          position: relative;
          margin-bottom: 20px;
          justify-content: flex-end;
          align-items: center;
          img{
            display: block;
            margin: 0;
          }
          h6{
            font-weight: 700;
            color: #fff;
            margin: 0;
            margin-right: 15px;
          }
        }
        nav{
          border: 0;
          margin: 0;
          margin-bottom: 24px;
          max-height: calc(100vh - 172px - 110px);
          overflow: auto;
          padding: 0 30px;
          ul{
            display: block;
            li{
              margin: 0;
              margin-bottom: 15px;
              padding: 0;
              text-align: right;
              &.dropdown {
                &.active .parent .arrow.act img {
                  content: url('/images/material/ico-arr-top.svg');
                }
                .parent {
                  align-items: center;
                  justify-content: flex-end;
                  .arrow {
                    display: block;
                    img {
                      content: url('/images/material/ico-arr-down.svg');
                    }
                  }
                }
                .submenu {
                  background: transparent;
                  box-shadow: none;
                  border: none;
                  border-radius: 0;
                  margin-top: 15px;
                  position: unset;
                  width: 100%;
                  ul {
                    padding: 0 27px 0 0;
                    text-align: right;
                    width: 100%;
                    &::before{
                      display: none !important;
                    }
                    li {
                      margin-bottom: 10px;
                      &.have-sub {
                        &.active ul {
                          display: none;
                        }
                        .parent .arrow {
                          position: unset;
                        }
                        ul {
                          background: transparent;
                          border: none;
                          height: auto;
                          left: unset;
                          margin-top: 10px;
                          position: relative;
                          right: unset;
                          top: unset;
                        }
                      }
                      a {
                        color: #fff;
                        font-size: 18px;
                        font-weight: normal;
                        &.active, &:hover {
                          color: #00A9E2;
                        }
                      }
                    }
                  }
                  figure {
                    display: none;
                  }
                }
              }
              a{
                padding: 0;
                font-size: 1.25rem;
                font-weight: 700;
              }
            }
          }
        }
        .tools{
          display: none;
        }
        .tools-mobile{
          padding: 0 30px;
          width: 74%;
          ul{
            margin: 0;
            li{
              display: flex;
              align-items: center;
              color: #fff;
              margin-bottom: 20px;
              form{
                width: 100%;
              }
              .search{
                position: relative;
                border-radius: 10px;
                width: 100%;
                &::after{
                  content: "";
                  position: absolute;
                  height: 1px;
                  width: 143%;
                  background: #fff;
                  opacity: 0.2;
                  top: -10px;
                  left: 0;
                }
                input[type="text"]{
                  background: transparent;
                  height: 50px;
                  padding: 0;
                  padding-left: 50px;
                  box-sizing: border-box;
                  margin: 0;
                  font-size: 1.25rem;
                  font-weight: 400;
                  color: #E5E8EA;
                  border: 0;
                  text-align: right;
                  &::placeholder {  
                    color: #CCEEF9;
                    font-size: 1.25rem;
                    opacity: 0.5;
                    font-style: normal;
                    font-weight: 300;
                    opacity: 1;  
                  }
                }
                .btn{
                  position:absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  left: 0;
                  font-size: 0;
                  padding: 0;
                  min-width: unset;
                  width: 40px;
                  height: 40px;
                  margin: 0;
                  border-radius: 5px;
                  border: 0;
                  background:  url('/images/material/ico-search.svg') no-repeat center;
                }
              }
            }
          }
        }
        .language {
          float: right;
          margin-right: 30px;
          position: relative;
          top: -62px;
          width: max-content;
          label {
            color: #fff !important;
            font-size: 1.25rem;
            font-weight: 400;
            &::after {
              content: url(/images/material/ico-arr-top.svg) !important;
              top: 57%;
            }
            .language__box {
              left: unset;
              right: 0;
              top: 130%;
              transform: translateX(0);
            }
          }
        }
      }
    }
    .logo{
      img{
        width: 235px;
      }
    }
    &.float{
      .wrapper{
        .right-header{
          nav{
            border: 0;
            ul{
              li{
                a{
                  color: #fff;
                }
              }
            }
          }
        }
      }
      .right-side{
        h6{
          color: #0B2C5E;
        }
      }
      .burger-nav{
        span{
          background: #0B2C5E;
        }
      }
    }
  }
  footer{
    padding: 50px 0;
  }
}



/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
  header{
    height: 70px;
    .wrapper{
      height: 70px;
      .right-header{
        width: 430px;
      }
    }
    .logo{
      img{
        width: 220px;
      }
    }
  }
  footer{
    .top{
      h6{
        font-size: 1.063rem;
      }
      .info-foot{
        ul{
          width: calc( 100% - 100px );
          li{
            a{
              font-size: 0.938rem;
            }
            &.loc{
              width: 260px;
              font-size: 0.938rem;
            }
            &.phone, &.fax{
              width: 160px;
            }
          }
        }
        figure{
          width: 100px;
          img{
            width: 60px;
          }
        }
      }
    }
    .bottom{
      p, a{
        font-size: 0.625rem;
      }
      ul{
        &.socmed{
          margin-left: 40px;
          li{
            img{
              width: 15px;
            }
          }
        }
      }
    }
  }
}



/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
  header{
    .logo{
      padding-left: 30px;
      img{
        width: 168px;
      }
    }
    .right-side{
      padding-right: 30px;
      h6{display: none;}
    }
    .wrapper{
      margin: 0;
      .right-header{
        width: 100%;
        right: 0;
        nav{
          max-height: calc(100vh - 182px - 130px);
          overflow: auto;
          ul li {
            a {
              font-size: 18px;
            }
            &.dropdown .submenu ul li a {
              font-size: 18px;
              padding: 0;
            }
          }
        }
        .top-mobile{
          height: unset;
          padding-top: 30px;
          padding-bottom: 40px;
          margin: 0;
        }
      }
    }
  }
  footer{
    padding: 40px 0;
    .top{
      h6{
        font-size: 0.938rem;
        margin-bottom: 12px;
      }
      .info-foot{
        ul{
          display: block;
          width: calc( 100% - 55px );
          li{
            a{
              font-size: 0.938rem;
            }
            &.loc{
              width: 100%;
              font-size: 0.938rem;
            }
            &.phone, &.fax{
              width: 100%;
            }
          }
        }
        figure{
          width: 36px;
        }
      }
    }
    .bottom{
      display: block;
      p{
        margin-bottom: 12px;
      }
      ul{
        &.socmed{
          margin: 0;
        }
      }
      .link-foot{
        justify-content: space-between;
      }
    }
  }
}