/* general styling
----------------------------------------------------------------------------------------------*/
@keyframes bounce {
  0% { 
    top: 0;
  }
  50% { 
    top: -5px;
  }
  100% { 
    top: 0;
  }
}
.title{
  h2,h1{
    color: #0B2C5E;
    font-size: 3.375rem;
    font-weight: 700;
    margin-bottom: 30px;
  }
  h4{
    color: #0B2C5E;
    font-weight: 700;
    margin-bottom: 14px;
  }
  h5{
    color: #00A9E2;
    font-weight: 700;
    margin-bottom: 6px;
  }
  h6{
    color: #1A2537;
  }
  &.twosides{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .slick-arrow {
      display: flex;
      justify-content: right;
      .arrow-prev, .arrow-next {
        cursor: pointer;
      }
    }
  }
}
.std-content{
  img {
    display: block;
    border-radius: 16px;
    margin-bottom: 29px;
    &.shadow {
      box-shadow: 0px 4px 40px 10px rgba(0, 38, 68, 0.15);
      margin-top: 20px;
    }
  }
  p{
    font-size: 1.063rem;
    line-height: 1.5;
    margin-bottom: 16px;
  }
  b{
    color: #0F3B7D;
    font-weight: 700;
  }
  h6{
    margin-bottom: 15px;
  }
  > img{
    border-radius: 16px;
    margin-bottom: 25px;
    width: 100%;
  }
  ul{
    li{
      position: relative;
      padding-left: 17px;
      &::after{
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background: #0B968D;
        top: 10px;
        left: 0;
      }
    }
  }
  small {
    color: #1A2537;
    font-size: 17px;
    line-height: 25;
  }
  .less-space {
    margin-bottom: 0;
  }
}
.with-bg{
  position: relative;
  width: 558px;
  height: 680px;
  margin-bottom: -30px;
  z-index: 9;
  &:before{
    content:"";
    position: absolute;
    top: 0;
    right: 0;
    background: url(/images/material/bg-blue-img.png) no-repeat center;
    background-size: 100%;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 10px;
  }
  figure{
    bottom: 0;
    position: absolute;
    img {
      display: block;
      height: auto;
      width: auto;
    }
  }
}
hr{
  opacity: 0.1;
  border: 1px solid #FFFFFF;
}
.static-banner{
  margin-top: 100px;
  position: relative;
  figure{
    height: 440px;
    &::after{
      content: "";
      position: absolute;
      background: linear-gradient(180deg, rgba(0, 23, 57, 0) 0%, #001739 100%);
      opacity: 0.5;
      // border-radius: 0px 16px 0px 0px;
      width: 100%;
      height: 100%;
      bottom: 0;
      left: 0;
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  figcaption{
    position: absolute;
    left: 50%;
    top: 200px;
    transform: translateX(-50%);
    width: 1170px;
    color: #fff;
    h5, h2{
      margin: 0;
      font-weight: 700;
      width: 50%;
    }
    h5 {
      background: linear-gradient(180deg, #CCEEF9 0%, #EAFAFF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
.middle{
  padding: 80px 0;
  .title{
    margin-bottom: 38px;
  }
  &.no-banner {
    padding-top: 160px;
  }
}

.pagging {
  padding: 50px 0 20px;
  ul {
    display: flex;
    justify-content: center;
    li {
      margin: 0 12px;
      a {
        color: #1A2537;
        text-transform: uppercase;
        width: 40px;
        height: 40px;
        display: block;
        line-height: 40px;
        text-align: center;
        box-shadow: 0 0px 10px rgba(0,0,0,0.04);
        background: #fff;
        &.active,
        &:hover {
          color: #134A9C;
        }
        &.active {
          font-weight: 700;
        }
        &.prev,
        &.next {
          background: transparent;
          box-shadow: none;
        }
      }
    }
  }
}
.title-download {
  background: #0F3B7D;
  border-radius: 5px 5px 0px 0px;
  display: flex;
  margin-bottom: -4px;
  padding: 16px 48px;
  h4 {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    margin: 0;
    padding-right: 30px;
    width: calc(100% - 140px);
    &:last-child {
      text-align: right;
      padding-right: 10px;
      width: 140px;
    }
  }
  ~ .download.full {
    span {
      width: calc(100% - 160px - 57px);
    }
    a {
      justify-content: right;
      width: 160px;
    }
  }
}
.download {
  align-items: center;
  background: transparentize(#E7EDF5,.5);
  border-radius: 8px;
  display: flex;
  margin-top: 16px;
  max-width: 100%;
  padding: 14px 24px;
  width: max-content;
  &.full {
    width: 100%;
    span {
      width: calc(100% - 140px - 40px);
    }
    a {
      width: 140px;
    }
  }
  img {
    display: block;
    margin-bottom: 0;
    margin-right: 16px;
  }
  span {
    color: #0B2C5E;
    display: block;
    font-size: 20px;
    line-height: 28px;
    margin-right: 30px;
    max-width: calc(100% - 140px - 40px);
    small {
      color: #4D5664;
      display: block;
      font-size: 15px;
      line-height: 21px;
    }
  }
  a {
    color: #1A2537;
    align-items: center;
    display: flex;
    font-size: 17px;
    font-weight: bold;
    line-height: 20px;
    text-transform: uppercase;
    transition: .2s all ease;
    justify-content: right;
    width: 140px;
    &:hover {
      color: #00A9E2;
      transition: .2s all ease;
      img {
        animation: bounce .2s;
        transition: .2s all ease;
      }
    }
    img {
      display: block;
      margin-left: 8px;
      margin-right: 0;
      position: relative;
      transition: .2s all ease;
    }
  }
}
/* responsive desktop
----------------------------------------------------------------------------------------------*/
// @media all and (max-width: 1600px) {}



/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1200px) {
  .with-bg{
    width: 425px;
    height: 521px;
    &:before{
      width: 391px;
    }
  }
  .title{
    h2,h1{
      font-size: 2.25rem;
      margin-bottom: 15px;
    }
  }
  .static-banner{
    margin-top: 80px;
    figure{
      height: 360px;
    }
    figcaption{
      width: 100%;
      left: 0;
      transform: unset;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}



/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
  .title h2, .title h1 {
    font-size: 1.563rem;
  }
  .with-bg{
    &::before{
      width: 290px;
      background: url(/images/material/bg-blue-img.png) no-repeat bottom;
      background-size: 100%;
    }
  }
  .title{
    &.twosides{
      .col{
        &:first-child{
          width: 390px;
        }
      }
    }
  }
  .middle{
    padding: 40px 0;
    &.no-banner{
      padding-top: 120px;
    }
  }
  .std-content{
    p{
      font-size: 0.938rem;
    }
  }
  .static-banner{
    margin-top: 70px;
    figure{
      height: 280px;
    }
  }
  .download{
    span{
      font-size: 17px;
    }
    a{
      font-size: 15px;
    }
    img{
      width: 28px;
      margin-right: 16px;
    }
  }
}



/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
  .title h2, .title h1 {
    font-size: 1.313rem;
  }
  .static-banner figcaption h5, .static-banner figcaption h2 {
    width: 70%;
  }
  .with-bg{
    margin-bottom: 30px;
  }
  .title{
    &.twosides{
      display: block;
      .col{
        width: 100%;
        &:first-child{
          width: 100%;
        }
      }
    }
  }
  hr{
    margin: 20px 0;
  }
  .static-banner{
    figure{
      height: 220px;
    }
  }
  .middle{
    .title{
      margin-bottom: 20px;
    }
  }
  .std-content{
    img{
      margin-bottom: 20px;
    }
  }
  .title-download{
    margin: 0;
    padding: 15px 20px;
    h4{
      font-size: 17px;
      &:last-child{
        display: none;
      }
    }
    ~ .download.full {
      span {
        width: calc(100% + 115px );
      }
      a {
        justify-content: unset;
        width: auto;
      }
    }
  }
  .download{
    &.full{
      span{
        margin-right: 10px;
        width: calc(100% - 75px);
      }
      a {
        width: auto;
      }
    }
    span{
      font-size: 14px;
      line-height: 1.25;
      margin-right: 10px;
      max-width: unset;
      width: calc(100% - 75px);
      small{
        display: none;
      }
    }
    a{
      font-size:0px;
      width: auto;
      img {
        margin-left: 0;
      }
    }
    img{
      width: 28px;
      margin-right: 16px;
      margin-bottom: 0;
    }
  }
  .paging{
    ul{
      li{
        margin: 0;
      }
    }
  }
  .g-recaptcha{
    transform:scale(0.77);-webkit-transform:scale(0.77);transform-origin:0 0;-webkit-transform-origin:0 0;
  }
}